import React from "react"
import { Box, FormErrorMessage } from "@chakra-ui/core"
import PageContainer from "../../components/PageContainer"
import CloseSideBar from "../../components/CloseSideBar"
import Heading from "../../components/typography/Heading"
import { useForm } from "react-hook-form"
import TextField from "../../components/FormFields/TextField"
import { Button } from "../../components/Buttons"
import { useDispatch } from "react-redux"
import { BaseDispatch } from "../../redux/store"
import Body from "../../components/typography/Body"

type FormValues = {
  password: string
  confirmPassword: string
}

export default function ResetPage(props) {
  const dispatch = useDispatch<BaseDispatch>()
  const { register, handleSubmit } = useForm<FormValues>()
  const [isLoading, setLoading] = React.useState(false)
  const [customerId, resetToken] = (props["*"] || "/").split("/")
  const [error, setError] = React.useState<string | null>(null)
  const [isComplete, setComplete] = React.useState(false)

  const onSubmit = async ({ password, confirmPassword }: FormValues) => {
    if (password !== confirmPassword) {
      setError("Passwords do not match")
      return
    } else if (password.length < 5) {
      setError("Password must be at least 5 characters long")
      return
    }
    setError(null)
    setLoading(true)
    try {
      const response = await dispatch.user.resetPassword({
        customerId: btoa(`gid://shopify/Customer/${customerId}`),
        resetToken,
        password,
      })

      // @ts-ignore
      const { success, error } = response
      if (!success) {
        setError(error)
      } else {
        setComplete(true)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <PageContainer>
      <CloseSideBar>
        <Heading as="h1" textAlign="center" mb="5rem">
          Reset Password
        </Heading>
        {!isComplete && (
          <Box
            as="form"
            w="400px"
            maxW="100%"
            mx="auto"
            textAlign="center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextField
              ref={register({ required: true })}
              aria-label="new password"
              name="password"
              type="password"
              placeholder="Enter new password"
              h="3rem"
              mb="20"
            />
            <TextField
              ref={register({ required: true })}
              aria-label="new password"
              name="confirmPassword"
              type="password"
              placeholder="Confirm new password"
              h="3rem"
              mb="20"
            />
            <Button w="100%" type="submit" isLoading={isLoading}>
              Save
            </Button>
            {error && (
              <Body role="alert" color="sunrise" mt="20">
                {error}
              </Body>
            )}
          </Box>
        )}
        {isComplete && (
          <Body color="primary" textAlign="center">
            Password successfully reset!
          </Body>
        )}
      </CloseSideBar>
    </PageContainer>
  )
}
